<!--
File: ConditionImportForm.vue
Description: form for bulk import/upload of IRI and Rutting data.
-->
<template>
  <div class="md-layout-item">
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region"
            :initial_value="selected_region" />
        </div>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <RoadsDropdown :label="$t('label.select_road')" v-model="selected_road" :initial_value="selected_road"
            :region_id="selected_region" />
        </div>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <SectionsDropdown :label="$t('label.select_section')" v-model="selected_section"
            :initial_value="selected_section" :region_id="selected_region" :road_id="selected_road" />
        </div>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <md-field>
            <label>{{ $t('label.select') }} {{ $t('label.iri_file') }}</label>
            <md-file v-model="fileNameIRI.name" @md-change="onIRIFileUpload($event)" />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <md-field>
            <label>{{ $t('label.select') }} {{ $t('label.rut_file') }}</label>
            <md-file v-model="fileNameRut.name" @md-change="onRutFileUpload($event)" />
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success md-raised" @click="templateDownload">
            {{ $t('buttons.download_template') }}
          </md-button>
          <md-button class="md-success md-raised" native-type="submit" required @click.native.prevent="validate"
            :disabled="upload_disabled">
            <md-icon>upload</md-icon>
            {{ $t('buttons.import') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.cancel') }}
          </md-button>
        </div>

      </md-card-actions>
    </md-card>

    <md-card v-if="show_preview == true">
      <md-card-content>
        <div>
          <label><strong>{{ $t('upload.status') }}: </strong> {{ status }} </label><br>
        </div>
        <md-table v-model="import_status" md-fixed-header class="paginated-table table-striped table-hover">
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('upload.line_number')">
              {{ item.line_number }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.type_description')">
              {{ item.type_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.is_error')">
              {{ item.is_error == true ? $t('upload.yes') : $t('upload.no') }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.message')" md-sort-by="message" width="10%">
              {{ item.message }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'import-sections',
    mixins: [messagesMixin],

    data() {
      return {
        content: [],
        importFileIRI: null,
        importFileRut: null,
        fileNameIRI: { name: '', },
        fileNameRut: { name: '', },
        comments: null,
        maxFileSize: null,

        selected_region: null,
        selected_road: null,
        selected_section: null,

        show_preview: false,
        status: null,
        batch_id: null,

        upload_in_progress: false
      }
    },

    components: {
      RegionsDropdown,
      RoadsDropdown,
      SectionsDropdown
    },

    mounted() {
      this.$store.dispatch('LOAD_SETTING_BY_NAME', 'MaxFileSize').then((res) => {
        this.maxFileSize = res * 1024   // in KB
      })
    },

    methods: {
      onClose,

      onIRIFileUpload(evt) {
        this.importFileIRI = evt[0]
        this.show_preview = false
        this.status = null
      },

      onRutFileUpload(evt) {
        this.importFileRut = evt[0]
        this.show_preview = false
        this.status = null
      },

      templateDownload() {
        this.$store.dispatch('IRI_IMPORT_TEMPLATE').then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res.data]))
          link.download = "iri_import_template.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })

        this.$store.dispatch('RUTTING_IMPORT_TEMPLATE').then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res.data]))
          link.download = "rutting_import_template.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
      },

      async validate() {
        const fileInfo = `${this.$t('label.iri_file')}: ${this.importFileIRI.name},
          ${this.$t('label.filesize', { size: this.importFileIRI.size })}<br><br>
          ${this.$t('label.rut_file')}: ${this.importFileRut.name}, 
          ${this.$t('label.filesize', { size: this.importFileRut.size })}`

        // Checks and validations
        let errors = []
        if (this.importFileIRI.type.includes("image") || this.importFileRut.type.includes("image")) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.wrong_file_type')}`)
        }
        if (this.importFileIRI.size > this.maxFileSize || this.importFileRut.size > this.maxFileSize) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.big_file')}`)
        }

        if (errors.length > 0) {
          errors.push('', fileInfo)
          this.errorMessage(errors.join('<br>'))
          return
        }

        // We passed all validations - let's try to import
        this.upload_in_progress = true
        let formData = new FormData();
        formData.append('file_iri', this.importFileIRI)
        formData.append('file_rutting', this.importFileRut)

        try {
          let res = await this.$store.dispatch('DATA100_IMPORT', { import_files: formData, section_id: this.selected_section })
          this.status = res.msg + ", " + res.result
          this.batch_id = res.batch_id

          this.successMessage(this.$t('route.import'), this.$t(`messages.import_file_queued`))
          this.importFileIRI = null
          this.importFileRut = null
          this.fileNameIRI.name = ''
          this.fileNameRut.name = ''
        } catch (err) {
          this.status = err.msg
          this.batch_id = err.batch_id
          this.errorMessage(this.$t(`messages.import_error`))
        }
        this.get_log(this.batch_id)
        this.upload_in_progress = false
        this.show_preview = true
      },

      get_log(batch_id) {
        this.$store.dispatch('IMPORT_LOG_ALL', batch_id).then(() => {
          console.log(this.import_status)
        })
      },
    },

    computed: {
      import_status() {
        return this.$store.state.ImportTools.import_status
      },

      upload_disabled() {
        return !this.importFileIRI || !this.importFileRut || !this.selected_section || this.upload_in_progress == true
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>